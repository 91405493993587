<template>
    <div> 
        <template v-if="type === 'esewa'">
            <Esewa :typeData="typeData" />
        </template>
        <template v-else-if="type === 'khalti'">
            <Khalti :typeData="typeData" />
        </template>
        <template v-else-if="type === 'visa'">
            <Visa :typeData="typeData" />
        </template>
        <template v-else >
            <button class="univ-btn w-100 mt-4" > Proceed </button>
        </template>
    </div>
</template>
<script>
import Esewa from '@/views/Payments/partials/Esewa'
import Khalti from '@/views/Payments/partials/Khalti'
import Visa from '@/views/Payments/partials/Visa'
export default {
    name:"Payment",
    components:{
        Esewa,Khalti,Visa,
    },
    props:{
        paymentType:{
            required:true,
        },
        paymentData:{
            required:true,
            type:[Array,Object],
        }
    },
    data() {
        return {
            type: this.paymentType,
            typeData:[],
        };
    },
    watch:{
        paymentType(value){
            let app = this;
            app.type = value;
        },
        paymentData(value){
            let app = this;
            app.typeData = value;
        }
    }
}
</script>