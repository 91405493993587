<template>
    <button class="univ-btn w-100 mt-4" @click="paymentProcess" > Khalti - Coming Soon</button>
</template>
<script>
//import router from "@/router"
export default {
    name:"Khalti",
    methods:{
        paymentProcess(){
            //router.push({ name: "PaymentSuccess",params: {payment:'khalti'} })
        }
    }
}
</script>